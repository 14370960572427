import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./footer.scss"
import { graphql } from "gatsby"

import List2 from "../components/list2"

import Calendar from "../assets/svg/regular/calendar-alt.svg"
import Clock from "../assets/svg/regular/clock.svg"
import Telephone from "../assets/svg/solid/phone-alt.svg"
import Mail from "../assets/svg/regular/envelope.svg"

import FacebookSVG from "../assets/svg/facebook.svg"
// import TwitterSVG from "../assets/svg/twitter.svg"
import InstagramSVG from "../assets/svg/instagram.svg"

const Footer = ({ siteMetadata, times}) => {
  const {
    social,

    opening,
  } = siteMetadata


  

  return (
    <footer>
      <section className="footer-wrapper">
        <Container className="footer-content text-white">
          <Row className="section-md">
            {/* <Col className="company-badge" lg="4" sm="12">
              <h4 className="text-center font-weight-bold mt-4 mb-2">
                Ihre Gewerbe Lizenzen
              </h4>
              <section className="img-badge d-flex flex-wrap justify-content-around align-items-center">
                {badges.map(badge => (
                  <img
                    width="90px"
                    className="my-2"
                    src={badge.img}
                    alt={badge.alt}
                  />
                ))}
              </section>
            </Col> */}
            {/* <br></br> */}
            <Col className="footer-aboutus" lg="6" sm="6">
              {/* <h4 className="ml-5 font-weight-bold mt-4 mb-2">
                Mehr Infos
              </h4> */}
              {/* <p className="font-size-80 text-justify">{about}</p> */}
              <section className="footer-social ml-5" lg="6" sm="6">
                <Row className="mb-2 ">
                  <a
                    href={social.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookSVG className="fill-svg" />
                  </a>
              
                  <a
                    href={social.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramSVG className="stroke-svg" />
                  </a>
              
                </Row>
              </section>
              <br></br>
              <h5 className="text-center font-weight-bold text-uppercase">
                Öffnungszeiten Hofladen
              </h5>
              <section className="opening ml-5">
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Calendar />}
                  value={times.wochentage}
                />
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Clock />}
                  value={times.wochentageffnungszeiten}
                />
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Calendar />}
                  value={times.wochenendeTage}
                />
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Clock />}
                  value={times.wochenendeffnugszeiten}
                />
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Telephone />}
                  value={
                    <a
                      style={{ color: "white" }}
                      className="white-text"
                      href="tel:+41794193333"
                    >
                      079 419 33 33{" "}
                    </a>
                  }
                />
                <hr />
              </section>
            </Col>
            <Col className="footer-contact ml-5" lg="4" sm="6">
              <h4 className="text-left font-weight-bold mt-4 mb-2">
                Büro & Anfragen
              </h4>
              <ul className="list-unstyled">
                {/* <li className="my-2">
                  <List2 svg={<Smartphone />} value={contact.mobile} />
                </li> */}
                <li className="my-2">
                  <List2
                    svg={<Telephone />}
                    value={
                      <a className="white-text" href="tel:0447500101">
                        044 750 01 01{" "}
                      </a>
                    }
                  />
                </li>
                <li className="my-2">
                  <List2
                    svg={<Mail />}
                    value={
                      <a
                        className="white-text"
                        href="mailto:info@fahr-erlebnis.ch"
                      >
                        info@fahr-erlebnis.ch
                      </a>
                    }
                  />
                </li>
                {/* <li className="my-2">
                  <List2 svg={<Pin />} value={<p>Chlosterstrasse</p>} />
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
        <Row className="footer-credits justify-content-center">
          {` © 2022 `} &nbsp;
          <a
            href="https://fahr-erlebnis.ch/impressum"
            rel="noopener noreferrer"
          >
            Fahr-Erlebnis
          </a>
        </Row>
      </section>
    </footer>
  )
}

export default Footer

export const FooterQuery = graphql`
  query FooterDataQuery {
    allContentfulOffnungszeitenHofladen {
      edges {
        node {
          id
          wochenendeTage
          wochenendeffnugszeiten
          wochentage
          wochentageffnungszeiten
        }
      }
    }
  }
`
